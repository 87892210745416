import './landing_3.scss'
export default function Landing_3() {
  return (
    <div className='landing_3'>
      <div className='image_wrapper flex flex-col items-center justify-center'>
        <div className='mx-auto'>
          <div className='w-full relative'>
            <img src={`/landing-page.png?t=${Date.now()}`} alt='landing-top' />
            <button
              className='absolute top-[624px] h-[76px] w-[470px] left-[115px]'
              onClick={() => {
                window.open('https://t.me/sinoent', '_blank')
              }}
            ></button>
            <button
              className='absolute top-[1221px] h-[76px] w-[470px] left-[115px]'
              onClick={() => {
                window.open('https://t.me/+xh67F9WxyTIyZGM9', '_blank')
              }}
            ></button>
            {/* <div className='absolute flex top-[900px] px-[30px] gap-[21px]'>
              <button
                className='w-[199px] h-[226px]'
                onClick={() => {
                  window.open('https://www.youtube.com/@psychopenguin1', '_blank')
                }}
              ></button>
              <button
                className='w-[199px] h-[226px]'
                onClick={() => {
                  window.open('https://www.youtube.com/@sinoentppshorts', '_blank')
                }}
              ></button>
              <button
                className='w-[199px] h-[226px]'
                onClick={() => {
                  window.open('https://www.youtube.com/@sinoentppmt', '_blank')
                }}
              ></button>
            </div> */}
            <div className='absolute flex items-center justify-center top-[735px] left-1/2 -translate-x-1/2 gap-[21px] z-10'>
              <div className='flex-1 py-4'>
                <iframe
                  title='sinoent'
                  frameBorder='none'
                  style={{ width: '325px', height: '410px' }}
                  src='//kr07.tocplus007.com/iframeChatLoader.do?userId=sinoent&color=FFA500'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
